var leap = [
  [21.425323637914353, 39.820088198489685],
  [21.427654218269886, 39.82213649908578],
  [21.42871357793261, 39.82439729307729],
  [21.428564106483126, 39.826178722041654],
  [21.428056866233273, 39.8286557354639],
  [21.426171360135797, 39.82949469291814],
  [21.42508894689962, 39.8297111580626],
  [21.42414480321929, 39.829332908603135],
  [21.42233562054093, 39.82787472208922],
  [21.421760904604362, 39.82758667262741],
  [21.420967525230246, 39.826546060998254],
  [21.42041045471977, 39.82665853228132],
  [21.42016866157231, 39.82514335985311],
  [21.42060476756682, 39.82328821185504],
  [21.422505513673425, 39.8218834481739],
]
var SpecialParking = [
  [21.42386885783928, 39.83128392756719],
  [21.423855801151824, 39.831322895360074],
  [21.423624556769006, 39.83113489559173],
  [21.423366661785725, 39.83122913288108],
  [21.423192551024137, 39.831342128342],
  [21.42301380047088, 39.83145046730888],
  [21.42278921267665, 39.831143115729795],
  [21.423713853210828, 39.82986926171206],
  [21.424243852425228, 39.829567773164385],
  [21.424379429833262, 39.8300476929498],
  [21.424285071514415, 39.830576375688025],
  [21.424173154552406, 39.830836380313784],
]
var park = [
  [24.83785570338497, 46.737324372981526],
  [24.839167378105834, 46.73392127330064],
  [24.837369367189563, 46.733042293374716],
  [24.83707087410722, 46.733040009418836],
  [24.836896029262224, 46.73310922252916],
  [24.836788379054347, 46.7331556401303],
  [24.836657326616745, 46.73318142783453],
  [24.8364935108966, 46.73310406649334],
  [24.836423304103672, 46.73304733476273],
  [24.83587291824639, 46.73349083884446],
]
var park1 = [
  [24.828950517373396, 46.72444691815201],
  [24.82891025841422, 46.72389993162718],
  [24.829077960944453, 46.72341207417651],
  [24.829366416727588, 46.723027696459205],
  [24.829695126059907, 46.72279115125598],
  [24.83005067203883, 46.722680264728254],
  [24.830352552883397, 46.72268025652009],
  [24.832069954985403, 46.7261987521028],
  [24.83031904620291, 46.72734447579793],
]

var VisitorsYellowShuttle = [
  [24.820496418619566, 46.70908343774435],
  [24.820159134245923, 46.70888879704677],
  [24.817292303494224, 46.711056536064945],
  [24.821105624899314, 46.7158434192276],
  [24.822617721724633, 46.71327505355195],
]
var leapPath = leap.map(y => {
  return { lat: y[0], lng: y[1] }
})
var SpecialParkingPath = SpecialParking.map(y => {
  return { lat: y[0], lng: y[1] }
})
var parkPath = park.map(y => {
  return { lat: y[0], lng: y[1] }
})
var park1Path = park1.map(y => {
  return { lat: y[0], lng: y[1] }
})
var VisitorsYellowShuttlePath = VisitorsYellowShuttle.map(y => {
  return { lat: y[0], lng: y[1] }
})
var marker = [
  {
    position: [21.422544150348976, 39.82610085712303],
    img:
      'https://cdn-icons-png.flaticon.com/512/3165/3165343.png',
  },
  {
    position: [21.423856623953423, 39.8305295312527],
    img:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.83782012388439, 46.7344521219667],
    img:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.831075812684364, 46.72558728126527],
    img:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
  {
    position: [24.820516349336494, 46.71266163039018],
    img:
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png',
  },
]
function drawPoly (google, smap) {
  const pPat = new google.maps.Polygon({
    paths: park1Path,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  pPat.setMap(smap)
  const VisitorsPath = new google.maps.Polygon({
    paths: VisitorsYellowShuttlePath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  VisitorsPath.setMap(smap)
  const p1h = new google.maps.Polygon({
    paths: parkPath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  p1h.setMap(smap)
  const Specialth = new google.maps.Polygon({
    paths: SpecialParkingPath,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  })
  Specialth.setMap(smap)
  const lth = new google.maps.Polygon({
    paths: leapPath,
    strokeColor: '#58007b',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#58007b',
    fillOpacity: 0.35,
  })
  lth.setMap(smap)
  marker.forEach((e, i) => {
    var ic = {
      url: e.img, // url
      scaledSize: new google.maps.Size(25, 35), // scaled size
    }
    var ia = {
      url: e.img, // url
      scaledSize: new google.maps.Size(25, 35), // scaled size
    }
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: new google.maps.LatLng(e.position[0], e.position[1]),
      map: smap,
      animation:
        i === 0 ? google.maps.Animation.BOUNCE : google.maps.Animation.DROP,
      icon: i === 4 ? ic : ia,
    })
  })
}
export {
  VisitorsYellowShuttlePath,
  park1Path,
  parkPath,
  SpecialParkingPath,
  leapPath,
  marker,
  drawPoly,
}
