<template>
  <div>
    <div class="google-map fill-height" ref="googleMap" />
    <va-modal v-model="createModal" no-outside-dismiss
      fullscreen
      hide-default-actions
      :title="$t('generic.Detailes') +' ' + routeName "
      :cancelText=" $t('modal.cancel')">
      <div v-if="loading" class="flex-center spinner-box">
        <SpringSpinner
          :animation-duration="1500"
          :color="this.$themes.primary"
          :size="80"
        >
        </SpringSpinner>
      </div>
      <div  v-else>
        <div class="row justify--space-between">
          <span>{{$t('expectedTime')}}<span>{{expextedTime}}</span></span>
          <span>{{$t('distance')}}<span>{{distance}}</span></span>
          <!-- <span>{{timeNow - expextedTime >= 3 ? '' :''}}</span> -->
          <span>{{$t('timeNow')}} <span>{{timeNow}}</span></span>

        </div>
        <div class="row flex-center">

          <div id="map" class="google-map  fill-height" ref="googleMapDetails" style="height: 69vh;"/>
          <div id="sidebar">
            <div id="panel"></div>
          </div>
        </div>
        <div class="d-flex justify--center mt-3">
          <va-button type="button" color="gray" flat @click="createModal = false">
            {{ $t('modal.cancel') }}
          </va-button>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import * as GoogleMapsLoader from 'google-maps'
import * as spinners from 'epic-spinners'
import axios from 'axios'
import { drawPoly } from '../../../helper/Pahts'
import firebase from 'firebase'

export default {
  name: 'google-map',
  data () {
    return {
      routeName: '',
      timeNow: '',
      distance: '',
      expextedTime: '',
      loading: true,
      createModal: false,
      IW: null,
      routes: [],
    }
  },
  components: {
    ...spinners,
  },
  mounted () {
    const self = this
    GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
    /* We stick to version updates instead of weekly channel update because
       using weekly updates you need to test them, but we prefer to use stable
       versions. */
    GoogleMapsLoader.VERSION = '3.35'
    GoogleMapsLoader.load((google) => {
      self.IW = new google.maps.InfoWindow()

      /* eslint-disable no-new */
      const map = new google.maps.Map(this.$refs.googleMap, {
        center: new google.maps.LatLng(21.42453962997633, 39.82571173709037),
        zoom: 11,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      })
      drawPoly(google, map)

      // const trafficLayer = new google.maps.TrafficLayer()
      // trafficLayer.setMap(map)
      var directionsService = new google.maps.DirectionsService()
      var delayFactor = 1
      function drawPath (path) {
      // Display a polyline of the elevation path.
        var pathOptions = {
          path: path,
          strokeColor: '#3304aa',
          strokeWeight: 5,
          opacity: 0.4,
          map: map,
        }
        return new google.maps.Polyline(pathOptions)
      }
      function render (route, org, destination, waypoints, i) {
        var request = {
          origin: org,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: waypoints,
        }
        directionsService.route(request, function (result, status) {
          new google.maps.Marker({
            position: org,
            map: map,
          })
          if (status === google.maps.DirectionsStatus.OK) {
            var po = drawPath(result.routes[0].overview_path)
            google.maps.event.addListener(po, 'click', function (evt) {
              self.routeName = route.RouteName
              self.createModal = true
              self.onRouteClick(route, result, org, destination)
            })
          } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            delayFactor++
            setTimeout(function () {
              render(route, org, destination, waypoints)
            }, delayFactor * 500)
          } else {
            console.log('Route: ' + status)
          }
        })
      }
      const firestore = firebase.firestore()
      firestore.collection('StaticRoads').get().then(async (querySnapshot) => {
        querySnapshot.forEach((doc, index) => {
          var route = doc.data()
          route.id = doc.id
          self.routes.push(route)
          var LongLat = route.FromLocation.split(',')
          var org = new google.maps.LatLng(Number(LongLat[0]), Number(LongLat[1]))
          var LongLatDes = route.toLocation.split(',')
          var destination = new google.maps.LatLng(Number(LongLatDes[0]), Number(LongLatDes[1]))
          var waypoints = []
          route.waypoints.forEach(point => {
            if (point.place_id) {
              waypoints.push({
                location: { placeId: point.place_id },
              })
            } else {
              waypoints.push({
                location: new google.maps.LatLng(point.latitude, point.longitude),
              })
            }
          })
          render(route, org, destination, waypoints, index)
        })
      })
    })
  },
  methods: {
    onRouteClick (route, result, org, destination) {
      this.loading = true
      this.createModal = true
      const self = this
      var req = {
        origin: org,
        destination: destination,
        waypoints: route.waypoints,
        departureTime: null,
      }
      axios.post('https://map-api-ubzex7qxpa-ew.a.run.app/routes/computeRouteWithTrafficLength', req).then(res => {
        this.loading = false
        self.timeNow = res.data[0].statistics.duration.text
        self.expextedTime = res.data[0].statistics.staticDuration.text
        self.distance = res.data[0].statistics.distance.text
        GoogleMapsLoader.KEY = 'AIzaSyC6Wt_40qWZ7up5hvZi9GwzxYWmwjnXggs'
        GoogleMapsLoader.VERSION = '3.35'
        GoogleMapsLoader.load((google) => {
          setTimeout(() => {
            const map = new google.maps.Map(this.$refs.googleMapDetails, {
              center: new google.maps.LatLng(21.42453962997633, 39.82571173709037),
              zoom: 11,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            })
            var colors = { TRAFFIC_JAM: '#d74342', SLOW: '#e69e01', NORMAL: '#4078c9' }
            const decodedPath = res.data[0].polyline.geoJsonLinestring.coordinates
            const decodedPathLatLng = decodedPath.map(elt => { return { lat: elt[1], lng: elt[0] } })
            route.ApiData = res.data[0]

            res.data[0].travelAdvisory.speedReadingIntervals.forEach((step) => {
              const currentPath = decodedPathLatLng.slice(step.startPolylinePointIndex === 0 ? 0 : (step.startPolylinePointIndex - 1), step.endPolylinePointIndex)
              var polylineOptionsActual = new google.maps.Polyline({
                path: currentPath,
                strokeColor: colors[step.speed],
                geodesic: true,
                strokeOpacity: 1,
                strokeWeight: 6,
              })
              polylineOptionsActual.setMap(map)
              google.maps.event.addListener(polylineOptionsActual, 'click', function (evt) {
                self.showIW(route, evt.latLng, map)
              })
            })
            const po = new google.maps.Polyline({
              strokeColor: 'transparent',
              geodesic: true,
              strokeOpacity: 0.1,
              strokeWeight: 6,
            })

            const display = new google.maps.DirectionsRenderer({
              preserveViewport: true,
              suppressMarkers: true,
              map,
              polylineOptions: po,
              panel: document.getElementById('panel'),
            })

            display.setDirections(result)

            // eslint-disable-next-line no-new
            new google.maps.Marker({
              position: destination,
              map: map,
              animation: google.maps.Animation.BOUNCE,
              icon: 'https://gcdnb.pbrd.co/images/hnKUay2TdxIv.png',

            })
            const iconA = {
              url: 'http://maps.google.com/mapfiles/kml/paddle/A.png', // url
              scaledSize: new google.maps.Size(35, 35), // scaled size
            }
            new google.maps.Marker({
              position: org,
              map: map,
              animation: google.maps.Animation.DROP,
              icon: iconA,
            })
          }, 100)
        })
      })
    },
    showIW (route, position, map) {
      const data = route?.ApiData
      let html = ''
      html += '<div dir="ltr">'
      html += `<span class="infotitleSc">${route.RouteName}</span><br/>`
      html += `<span class="infosegment">${this.$t('distance')}: ${data.statistics.distance.value / 1000} ${this.$t('KM')} </span><br/>`
      html += `<span class="infosegment"> ${this.$t('TimeWithoutCrowding')}: ${Math.round(route.DeafultTime / 60)} ${this.$t('minuits')}</span><br/>`
      html += `<span class="infosegment"> ${this.$t('timeNow')}: ${this.fromatTime(data.statistics.duration.value, true)}</span><br/>`

      html += '<br/>'
      html += `<span class="infonormal">${this.$t('normal')}: ${data.statistics.normal.distance.value / 1000} ${this.$t('KM')} (${data.statistics.normal.distance.percentage})</span><br/>`
      html += `<span class="infoslow">${this.$t('Slow')}: ${data.statistics.slow.distance.value / 1000} ${this.$t('KM')}  (${data.statistics.slow.distance.percentage})</span><br/>`
      html += `<span class="infojam">${this.$t('crowded')}: ${data.statistics.jam.distance.value / 1000} ${this.$t('KM')}  (${data.statistics.jam.distance.percentage})</span><br/>`
      html += '</div>'

      if (this.IW) {
        this.IW.setContent(html)
        this.IW.setPosition(position)
        this.IW.open({ map: map })
      }

      console.log('showin poly click', route)
    },
    fromatTime (time, isSec = false) {
      let text

      const durationInSec = isSec ? parseInt(time) : parseInt(time) * 60

      if (durationInSec < 60) text = `${durationInSec}${this.$t('second')}`
      text = `${Math.round(durationInSec / 60)} ${this.$t('minuits')}`
      return text
    },
  },
}
</script>
<style lang="scss" >
  .routeType {
    max-width: 130px;
    text-align: end;
  }

  .infojam {
    color: #d74342;
    font-weight: 700;
  }

  .infonormal {
    color: #4078c9;
  }

  .infoslow {
    color: #e69e01;
  }

  .infotitlePr {
    color: #6262d0;
    font-weight: 700;
  }

  .infotitleSc {
    color: #62c6d0;
    font-weight: 700;
  }

  .infosegment {
    color: #2d2c2c;
    font-weight: 700;
  }

  .gm-style-iw-c {
    direction: ltr;
  }

  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
  }

  i.fa.fa-exclamation {
    color: red !important;
  }

  .va-modal__inner {
    max-width: 100%;
  }

  .List .va-modal__inner {
    max-width: 100%;
    width: 992px;
  }

  .unitsNames-width {
    max-width: 177px;
  }

  #sidebar {
    flex-basis: 15rem;
    flex-grow: 1;
    padding: 1rem;
    max-width: 30rem;
    height: 75vh;
    box-sizing: border-box;
    overflow: auto;
  }

  #map {
    flex-basis: 0;
    flex-grow: 4;
    height: 75vh;
  }

  .va-modal__close {
    right: auto;
    left: 1rem;
  }
</style>
